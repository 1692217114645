.default {
  opacity: 0;
}

.appear {
  opacity: 1
}

.appearActive {
  opacity: 1
}

.appearDone {
  opacity: 1
}

.enter {
  opacity: 1
}

.enterActive {
  opacity: 1
}

.enterDone {
  opacity: 1
}

.exit {
  opacity: 0;
}

.exitActive {
  opacity: 0;
}

.exitDone {
  opacity: 0;
}