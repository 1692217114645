html {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-family: "Poppins", sans-serif !important;
}

#root {
  height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollbar::-webkit-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.session-expired-modal-pattern-root {
  .react-pattern-lock__point-inner {
    background-color: var(--color-secondary);
  }

  .react-pattern-lock__connector {
    background-color: var(--color-secondary);
  }
}

.pincode-input-text {
  border-radius: 5px;
  border: 1px solid lightgray !important;
}

.pincode-input-text:focus {
  border: 1px solid #85c440 !important
}


.ai-input-container input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}


#reve-chat-widget-holder .pageHolder.banner-2 {
  margin: 0px !important;
}

#reve-chat-container-div {
  margin-bottom: -10px !important;
  margin-right: -10px !important;
  transform: scale(0.85) !important;
}
